.members-page {
  padding-top: 25px;
  padding-bottom: 27px;
  padding-left: 40px;
  padding-right: 30px;
}

.content-inner {
  width: calc(100% - 240px);
  margin-left: 240px;
  transition: all 0.3s ease;
}

.content-inner.shrinked {
  width: calc(100% - 120px);
  margin-left: 120px;
}

.container-fluid {
  padding: 30px 30px;
  margin-top: 6rem;
}

footer.main-footer {
  background: #fff;
  padding: 15px 30px;
  border-top: 0.07rem solid #eee;
  box-shadow: 0 1px 15px 1px rgb(52 40 104 / 8%);
}

footer p {
  color: #98a8b4;
  margin: 0;
  font-size: 0.85rem;
  font-weight: 500;
}

.text-gradient-02 {
  background: #464f70;
  background: linear-gradient(135deg, #464f70 0%, #464f70 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

footer.main-footer a.nav-link {
    color: #98a8b4;
}

footer.main-footer .nav-link {
    padding: 0 1rem;
    font-size: 0.85rem;
}

.nav-link {
    display: block;
    padding: 0.7rem 1rem;
}