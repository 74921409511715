.bg-fixed-01 {
  background: linear-gradient(135deg, rgba(46, 52, 81, 0.5) 0%, rgba(46, 52, 81, 0.95) 100%),
    url('../../../../assets/images/bg-login.jpg') no-repeat center center;

  overflow: auto;
  height: 100%;
}

/*
 * Forgot Password
 */
.password-form {
  background: #fff;
  font-size: 0.9rem;
  padding: 3.8rem 3rem;
  display: block;
  width: 400px;
  max-width: 400px;
  border-radius: 4px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 15px 25px 2px rgba(0, 0, 0, .14), 0 5px 30px 5px rgba(0, 0, 0, .12);
  z-index: 2;
}

.password-form h3 {
  color: #20294c;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}

.password-form .group {
  position: relative;
  margin-bottom: 30px;
}

.password-form .button {
  margin: 25px 0 0 0;
}

.password-form .back {
  color: #40545a;
  text-align: center;
  margin-top: 30px;
}

@media (max-width: 572px) {
  .password-form {
    width: 100%;
    margin: 1rem;
  }
}

/*
 * LOGO
 */
.logo-centered {
  width: 140px;
  margin: 0 auto;
}

.logo-centered img {
  width: 140px;
  margin-bottom: 50px;
}