.group {
  position: relative;
  margin-bottom: 30px;
}

.button {
  margin: 25px 0 0 0;
}

.back {
  color: #40545a;
  text-align: center;
  margin-top: 30px;
}