.page-header {
  position: relative;
  z-index: 99;
  padding: 0 0 30px 0;
  /* width: 100%; */
}

.page-header-title {
  color: #20294c;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: auto !important;
}
