/*
 * 05. INVOICE
 */
.invoice {
  background: #fff;
  margin-bottom: 30px;
}

.invoice-container {
  padding: 1rem;
  margin: 0 auto;
}

.invoice hr {
  border-color: rgba(52, 40, 104, 0.15);
  padding: 2rem 0;
}

.invoice .invoice-top {
  width: 100%;
  padding: 3rem 2rem 2rem 2rem;
}

.invoice .invoice-top h1 {
  color: #20294c;
  font-size: 3rem;
  text-transform: uppercase;
  margin-right: 10px;
  margin-bottom: 0;
}

.invoice .invoice-top span {
  display: block;
  font-weight: 600;
  font-style: italic;
}

.invoice .invoice-header {
  width: 100%;
  padding: 3rem 2rem;
  font-size: 1.1rem;
}

.invoice .invoice-header .invoice-logo {
  display: block;
}

.invoice .invoice-header .invoice-logo img {
  width: 150px;
}

.invoice .invoice-header .details .company-name {
  color: #20294c;
  font-weight: 600;
  font-size: 1.6rem;
}

.invoice .invoice-header .client-details .title {
  color: #20294c;
  font-size: 1.6rem;
  font-weight: 600;
}

.invoice-date {
  padding: 0 2rem;
}

.invoice-date span {
  font-size: 1.4rem;
  font-weight: 600;
  color: #20294c;
  margin: 50px 0;
}

.invoice .desc-tables {
  padding: 0 2rem;
}

.invoice .table thead {
  background: none;
  border-radius: 0;
  border-bottom: 0.07rem solid rgba(52, 40, 104, 0.15);
}

.invoice .table thead th {
  vertical-align: middle;
  border: 0 none;
  padding: 1rem 0.85rem;
  color: #00a1cd;
  font-weight: 600;
  text-transform: uppercase;
}

.invoice .table td {
  border-bottom: 0 none;
  padding: 1.2rem;
  vertical-align: middle;
  color: #464f70;
  font-weight: 600;
}

.invoice .table td .description {
  color: #98a8b4;
  font-size: 0.9rem;
  font-weight: 500;
}

.invoice .invoice-footer {
  background: #20294c;
  padding: 4rem 2rem;
  margin-top: 6rem;
}

.invoice .bank .title {
  color: #aea9c3;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.invoice .total {
  text-align: center;
  line-height: 2.2rem;
}

.invoice .total .title {
  color: #aea9c3;
  font-size: 1.4rem;
  font-weight: 600;
}

.invoice .total .number {
  color: #00a1cd;
  font-size: 2.4rem;
  font-weight: 700;
}

.invoice .total .taxe {
  color: #aea9c3;
}

.invoice .footer-bottom {
  margin-top: 4rem;
  text-align: center;
}

.invoice .thx i {
  color: #00a1cd;
  font-size: 3rem;
  margin-bottom: -15px;
}

.invoice .thx span {
  display: block;
  color: #aea9c3;
  font-size: 1.6rem;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .invoice-container {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 576px) {
  .invoice-container {
    width: 100%;
    margin: 0;
  }
}

.action-button {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(25px, 38px, 0px);
  cursor: pointer
}

.table-body-border-none {
  border-top: 0 !important;
}

.widget-header.bordered {
  border-bottom: 0.07rem solid #eee;
}

.widget-header.no-actions {
  padding: 1.49rem;
}

.clean-filters {
  margin-right: 0.4em;
}