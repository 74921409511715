
html {
  font-size: 14px;
}

body,
html {
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #98a8b4;
  background-color: #f2f3f8;
}

a {
  color: #464f70;
  text-decoration: none;
  transition: all 0.5s ease;
}

a:focus,
a:hover {
  color: #00a1cd;
  text-decoration: underline;
}

*[class*="icon-"] {
  transform: translateY(3px);
}

body {
  overflow-x: hidden;
}

a,
i,
span {
  display: inline-block;
  text-decoration: none;
}

a:hover,
a:focus,
i:hover,
i:focus,
span:hover,
span:focus {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

ol,
ul,
li {
  list-style: none;
}

section {
  padding: 35px 0;
}

.section {
  padding: 35px 0;
}

canvas {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.1;
  color: #20294c;
}

h1,
.h1 {
  font-size: 2.5rem;
  font-weight: 600;
}

h2,
.h2 {
  font-size: 1.5rem;
  font-weight: 500;
}

h3,
.h3 {
  font-size: 1.35rem;
  font-weight: 500;
}

h4,
.h4 {
  font-size: 1.2rem;
  font-weight: 500;
}

h5,
.h5 {
  font-size: 1rem;
  font-weight: 500;
}

h6,
.h6 {
  font-size: 0.85rem;
  font-weight: 500;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.btn {
  font-weight: 600 !important;
  line-height: 1.25;
  border: 2px solid transparent;
  padding: 10px 20px;
  font-size: 0.85rem;
  border-radius: 60px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
}

.btn-primary {
  color: #fff;
  background-color: #464f70;
  border-color: #464f70;
}

.btn-primary i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-primary:hover {
  color: #fff;
  background-color: #07a2ce;
  border-color: #07a2ce;
}

.btn-primary:active {
  color: #fff;
  background-color: #464f70;
  border-color: #464f70;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  outline: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #9086b9;
  border-color: #9086b9;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #594f82;
  border-color: #464f70;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #464f70;
  border-color: #464f70;
}

.btn-primary.dropdown-toggle.la-angle-down:after {
  display: none;
}

.btn-secondary {
  color: #20294c;
  background-color: #e4e8f0;
  border: 2px solid #e4e8f0;
}

.btn-secondary i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #20294c;
  border-color: #20294c;
}

.btn-secondary:focus,
.btn-secondary.focus {
  border-color: transparent;
  box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #9e9ead;
  background-color: #edf0f5;
  border-color: #edf0f5;
}

.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #20294c;
  background-image: none;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  margin-right: 3em;
}

.btn-info i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #31b0d5;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #5bc0de;
}

.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #31b0d5;
  background-image: none;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
}

.btn-success i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #5cb85c;
}

.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44;
  background-image: none;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
}

.btn-warning i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #ec971f;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #f0ad4e;
}

.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #ec971f;
  background-image: none;
}

.btn-danger {
  color: #fff;
  background-color: #c9302c;
}

.btn-danger i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-danger:hover {
  color: #fff;
  background-color: #d84949;
  border-color: #d84949;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #fe195e;
}

.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c;
  background-image: none;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.padding-b-50 {
  padding-bottom: 50px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-right-25 {
  padding-right: 25px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-left-25 {
  padding-left: 25px !important;
}

.over-flow-hidden-outline-none {
  overflow: hidden;
  outline: none;
}

.width-10-percent {
  width: 10%;
}

table {
  font-size: 1rem;
  color: #98a8b4 !important;
}

.table thead {
  background-color: rgba(52, 40, 104, 0.05);
}

.table thead th {
  vertical-align: middle;
  border: 0 none;
  padding: 20px 12px 20px 12px;
  color: #464f70;
  font-weight: 600;
  background: #F5F4F7;
  border-radius: 4px;
}

.table td {
  border-top: 0;
  padding: 0.85rem;
  vertical-align: middle;
  font-weight: 500;
  border: 0;
  color: #98A8B4;
}

.table-hover tbody tr:hover td {
  color: #212529;
}


.table-bordered th,
.table-bordered td {
  border: 0.07rem solid #eee;
}

.table .td-actions a i {
  background: transparent;
  color: rgba(52, 40, 104, 0.4);
  font-size: 1.6rem;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.4s ease;
}

.table .td-actions a i.edit:hover,
.table .td-actions a i.more:hover {
  background: #464f70;
  color: #fff;
}

.table .td-actions a i.delete:hover {
  background: #00a1cd;
  color: #fff;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: rgba(52, 40, 104, 0.02);
  color: #464f70;
}

.dataTables_wrapper.container-fluid {
  padding: 0 1.07rem;
  margin-top: 6rem;
}

.btn-shadow,
.btn-shadow a {
  color: #464f70;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 1px 15px 1px rgba(52 40 104 0.15);
}

.em-separator.separator-dashed {
  border: 1px dashed #eee;
}

.em-separator {
  height: 0;
  margin: 20px 0;
}
.form-control-label {
  color: #20294c;
  font-weight: 500;
}

.page-header-title {
  color: #20294c;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: auto !important;
  font-family: "Noto Sans", sans-serif;
}


input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="city"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select,
textarea {
  outline: none;
  box-shadow: none !important;
  field-sizing: content;
}

.form-control {
  font-size: 1rem;
  line-height: 1.25;
  color: #20294c;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0.75rem 1.07rem;
}

.form-control::-moz-placeholder {
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #94a4b0;
}

.form-control::-webkit-input-placeholder {
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #94a4b0;
}

.form-control:-ms-input-placeholder {
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #94a4b0;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.85rem + 2px);
}

.form-control:focus,
.has-success .form-control:focus,
.has-danger .form-control:focus,
.has-warning .form-control:focus,
.has-info .form-control:focus {
  border-color: #464f70;
}

.has-success .form-control {
  border-color: #5cb85c;
}

.has-danger .form-control {
  border-color: #fe195e;
}

.has-warning .form-control {
  border-color: #f0ad4e;
}

.has-info .form-control {
  border-color: #31b0d5;
}

.form-control-plaintext {
  color: #20294c;
  font-weight: 300;
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.invalid-feedback {
  margin: 0;
  font-size: 0.95rem;
  color: #fe195e;
  display: block;
}

button,
input[type="submit"] {
  cursor: pointer;
  font-family: inherit;
  font-weight: 300 !important;
}

form label {
  color: #20294c;
  font-weight: 500;
}

.input-group span.input-group-addon {
  font-weight: 500;
  border-radius: 0;
  border: 0 none;
  padding: 0.75rem 1.07rem;
  text-align: center;
}

.input-group span.input-group-addon i {
  font-size: 1.4rem;
}

.input-group span.input-group-addon.addon-primary {
  background-color: #464f70;
  color: #fff;
}

.input-group span.input-group-addon.addon-secondary {
  background-color: #e4e8f0;
  color: #20294c;
}

.input-group span.input-group-addon.addon-orange {
  background-color: #00a1cd;
  color: #fff;
}

.input-group .input-group-btn .btn {
  padding: 0.9rem 1.07rem;
  border-radius: 0;
  font-weight: 500;
}

.input-group .input-group-btn .btn i {
  font-size: 1.4rem;
}

.input-group .dropdown-toggle {
  background: #f5f5f5;
  color: #94a4b0;
}

.input-group ul.dropdown-menu {
  padding: 15px;
  color: #94a4b0;
  border-radius: 0;
}

.input-group ul.dropdown-menu a {
  padding: 5px 0;
  color: inherit;
  text-decoration: none;
}

.input-group ul.dropdown-menu a:hover {
  color: #464f70;
}

.material-input input {
  font-size: 1rem;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e4e8f0;
  font-family: "Montserrat", sans-serif;
}

.material-input input:focus {
  outline: none;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.material-input label {
  color: #98a8b4;
  font-size: 0.9rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.material-input input:focus ~ label,
.material-input input:valid ~ label {
  top: -20px;
  font-size: 0.8rem;
  color: #464f70;
}

.material-input .bar {
  position: relative;
  display: block;
  width: 100%;
}

.material-input .bar:before,
.material-input .bar:after {
  content: "";
  height: 1px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #464f70;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.material-input .bar:before {
  left: 50%;
}

.material-input .bar:after {
  right: 50%;
}

.material-input input:focus ~ .bar:before,
.material-input input:focus ~ .bar:after {
  width: 50%;
}

.material-input .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.material-input input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.form-control-invalid {
  border-color: #dc3545 !important;
}

.styled-radio {
  position: relative;
  padding-left: 35px;
  text-align: left;
}

.styled-radio label {
  cursor: pointer;
}

.styled-radio label:before,
.styled-radio label:after {
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 50%;
}

.styled-radio label:before {
  left: 0;
  width: 20px;
  height: 20px;
  margin: -15px 0 0;
  background: rgba(52, 40, 104, 0.1);
  box-shadow: 0 0 1px #eee;
}

.styled-radio label:after {
  left: 5px;
  width: 10px;
  height: 10px;
  margin: -10px 0 0;
  opacity: 0;
  background: #464f70;
  -webkit-transform: translate3d(-15px, 0, 0) scale(0.5);
  transform: translate3d(-15px, 0, 0) scale(0.5);
  -webkit-transition: opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
}

.styled-radio input[type="radio"] {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}

.radio-disabled,
.radio-disabled.styled-radio label {
  cursor: not-allowed;
  opacity: 0.8;
}

.styled-radio input[type="radio"]:checked + label:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.custom-control-descfeedback {
  margin-bottom: 0.5rem;
}

.file-upload {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 200px;
  padding: 5px 10px;
  font-size: 1rem;
  text-align: center;
  color: #ccc;
}

.file-upload-wrapper .card.card-body .file-upload-message {
  position: relative;
  top: 50px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.file-upload .mask.rgba-stylish-slight {
  opacity: 0;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.file-upload-wrapper .card.card-body input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 5;
}

.file-upload i {
  font-size: 4rem;
}

.thumbnail {
  overflow: hidden;
  width: 50px;
  height: 50px;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.input-type-file {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.badge-text {
  background: #e4e8f0;
  color: #fff;
  padding: 0.4rem 0.7rem;
  border-radius: 50px;
  font-size: 0.9rem;
  text-align: center;
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  vertical-align: middle;
}

.badge-text.danger
.btn.danger {
  background: #df0935;
}

.badge-text.badge-text-small {
  padding: 0.2rem 1rem;
}

.badge-text.info,
.btn.info {
  background: #08a6c3;
  color: white;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="city"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select,
textarea {
  outline: none;
  box-shadow: none !important;
}

.badge-text.success,
.btn.success {
  background: #60c400;
  color: white;
}

.badge-text.danger,
.btn.danger {
  background: #df0935;
  color: white;
}

.badge-text.alert,
.btn.alert {
  background: #f6aa1c;
  margin: 0;
  color: white;
}

.width-700px {
  width: 700px;
}

.has-shadow {
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}

.btn-send-email {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.font-size-large {
  font-size: x-large;
}

.text-align-center {
  text-align: center;
}
.flex-row {
  display: flex;
}

.feature-img {
  overflow: hidden;
  width: 120px;
  height: 120px;
}

.feature-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.section-title h1,
.section-title h2,
.section-title h3,
.section-title h4,
.section-title h5,
.section-title h6 {
  color: #20294c;
  font-weight: 600;
  margin-bottom: 0;
}

.custom-select {
  color: #20294c;
  background-color: #f8f8f8 !important;
  border-color: #f8f8f8;
  font-weight: 600 !important;
  line-height: 1.25;
  border: 2px solid transparent;
  padding: 10px 20px;
  font-size: 0.85rem;
  border-radius: 60px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.option {
  background-color: #f8f8f8;
  max-height: 303.434px;
  overflow: hidden;
  min-height: 0px;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 38px, 0px);
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-container-items {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(83px, 38px, 0px);
}

.dropdown-container-items a {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none;
}

.height-44 {
  height: 44px;
}

@media only screen and (max-width: 1199px) {
  .margin-top-card {
    margin-top: 25px;
  }
}

.clickable {
  cursor: pointer;
}

.padding-left-33 {
  padding-left: 33px !important;
}

.custom-button-disabled {
  background-color: #e53c5b;
  color: #fff;
  border-color: #e53c5b;
}

/*
 * PAGES NAV
 */

.page-item:first-child .page-link {
  margin-left: 0;
  border-radius: 50%;
  font-size: 1rem;
  margin-right: 5px;
}

.page-item:last-child .page-link {
  border-radius: 50%;
  font-size: 1rem;
  margin-left: 5px;
}

.page-item.active .page-link {
  z-index: 2;
  color: #464f70;
  background-color: transparent;
  font-weight: 600 !important;
}

.page-item.disabled .page-link {
  color: rgba(52, 40, 104, 0.15);
  pointer-events: none;
  cursor: not-allowed;
  background-color: transparent;
}

.page-link {
  padding: 0.5rem;
  line-height: 1rem;
  text-align: center;
  color: #aea9c3;
  margin: 0 0.3rem;
  background-color: transparent;
  border: 0 none;
  font-size: 1rem;
}

.page-link:focus, .page-link:hover {
  color: #464f70;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
}

.page-item .page-link span {
  font-size: 2rem;
}

.pagination {
  align-items: center;
}

.widget-footer {
  background: #fff;
  padding: 2rem 1.07rem 0rem 1.07rem;
  position: relative;
}

.label-filter {
  font-weight: normal;
  padding-right: 15px;
}

.text-align-right {
  text-align: right;
}

.input-filter {
  line-height: 1.50;
}

.w-65 {
  width: 65%;
}

.request-filters {
  justify-content: space-between!important;
  align-items: center!important;
  padding-bottom: 5rem!important;
  padding-top: 1rem!important;
  padding-right: 10px;
}

@media screen and (max-width: 1399px) {
  .padding-top-button-filter-lg {
    padding-top: 2rem;
  }
}

.h-700px {
  height: 700px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.p-10 {
  padding: 10px;
}

.modal-header button {
  border: none;
  background: transparent;
  font-size: 25px;
}

.modal-header h5 {
  font-size: 1.25rem;
}

.red-color {
  color: red;
}

.green-color {
  color: green;
}

.cross-out {
  text-decoration: line-through !important;
}

/*
 * BREADCRUMB
 */
 .breadcrumb {
  background: none;
  z-index: 50;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.breadcrumb li.breadcrumb-item {
  color: #94a4b0;
  font-weight: 500;
}

.breadcrumb li.breadcrumb-item i {
  font-size: 1.2rem;
  vertical-align: middle;
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #94a4b0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "-";
}

ul.breadcrumb li {
  display: inline;
}

.no-gender-icon-select {
  width: 20px;
  height: 20px;
  background-color: transparent;
}
