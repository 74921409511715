#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;
}

.canvas {
  // position: absolute;
  margin: auto;
  padding: 100px
  // top: 50%; left: 50%;
  // -webkit-transform: translate(-50%,-50%);
  //     -ms-transform: translate(-50%,-50%);
  //         transform: translate(-50%,-50%);
}

.loader-logo {
  display: block;
  width: 120px;
  margin-bottom: 25px;
}