.container-fluid {
  padding: 30px 30px;
}

.widget {
  background: #fff;
  border-radius: 0;
  border: none;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}

.widget-body {
  padding: 1.4rem;
  min-height: 300px;
  background-color: white;
}

.nav-tabs {
  border-bottom: 2px solid #eee;
}

.nav-tabs a {
  color: #464f70;
  text-decoration: none;
  transition: all 0.5s ease;
}

.nav-tabs .nav-item {
  position: relative;
}

.nav-link {
  display: block;
  padding: 0.7rem 1rem;
}

.nav-tabs .nav-link {
  border: 0 none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #00a1cd !important;
  background-color: #fff;
  border-bottom: 2px solid #464f70 !important;
  margin-bottom: -1px;
  border-color: #dee2e6 #dee2e6 #fff;

}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  border: 0 none;
}

.nav-tabs .nav-link i {
  color: #aea9c3;
}

.nav-tabs>li>a {
  border: none;
}

.nav-tabs>li.active>a,
.nav-tabs>li>a:hover::after {
  border: none;
  color: #464f70 !important;
  background: transparent;
  height: 2px;
  transition: all 0.2s ease 0s;
}

.nav-tabs>li.active>a::after,
.nav-tabs>li:hover>a::after {
  transform: scale(1);
}

.nav-tabs .nav-link.disabled {
  color: #98a8b4;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.nav-tabs .dropdown-toggle::after {
  border: 0 none;
}

.nav-tabs .dropdown-item {
  color: #464f70;
  padding: 0.65rem 1.5rem;
}

.nav-tabs .dropdown-item.active,
.nav-tabs .dropdown-item:active {
  color: #fff !important;
  background-color: #464f70;
}

.nav-left.nav-tabs {
  float: left;
  border-bottom: 0;
  border-radius: 0;
  display: table;
  padding: 0.65rem 1.07rem;
}

.nav-left.nav-tabs .nav-item {
  text-align: center;
}

.nav-link i {
  color: #aea9c3;
}

.widget-header {
  background: #fff;
  padding: 0.85rem 1.4rem;
  position: relative;
  width: 100%;
}

.widget-header.bordered {
  border-bottom: 0.07rem solid #eee;
}

.widget-header.no-actions {
  padding: 1.49rem;
}

.widget-header h1,
.widget-header h2,
.widget-header h3,
.widget-header h4,
.widget-header h5,
.widget-header h6 {
  color: #20294c;
  margin-bottom: 0;
}

.overflow-scroll {
  background: #fff;
  height: 100%;
}

.min-height-0 {
  min-height: 0;
}

.history-table-container {
  max-height: 600px;
  overflow: hidden;
  outline: none;
  display: block;
  overflow: auto;
}

.history-table-container table tbody  {
  border-top: 0 !important
}

.history-table-container>table>tbody>tr:nth-of-type(odd)>* {
  font-size: 1rem;
  color: #98a8b4 !important;
}