.widget {
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 30px;
}

.has-shadow {
  box-shadow: 0 1px 15px 1px rgb(52 40 104 / 8%);
}

.widget-header.bordered {
  border-bottom: 0.07rem solid #eee;
}

.widget-header {
  background: #fff;
  padding: 0.85rem 1.4rem;
  position: relative;
  width: 100%;
}

.widget-header h2 {
  color: #20294c;
  font-size: 1.2rem;
  display: table-cell;
  vertical-align: middle;
  margin: 0;
}

.widget-options {
  z-index: 999;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  float: right;
}

.widget-options .dropdown-menu {
  border: none;
  min-width: auto;
  font-size: 1rem;
  border-radius: 4px;
  padding: 1.4rem 1.8rem;
  text-align: left;
  transform: translateX(calc(-100% + 5px));
  box-shadow: 1px 1px 30px rgb(0 0 0 / 15%);
}

.dropdown-menu {
  border-color: #eee;
  box-shadow: 1px 1px 30px rgb(0 0 0 / 15%);
}

.widget-options .dropdown-menu .dropdown-item {
  padding: 0.5rem 0;
}

.widget-options .dropdown-menu a {
  color: #20294c;
  font-weight: 500;
}

.widget-body {
  padding: 1.4rem;
}

#external-events {
  z-index: 2;
}

#external-events .fc-event {
  margin: 1em 0;
  cursor: grab;
  overflow: hidden;
  padding-right: 3px;
}

.fc-event,
.fc-event:hover {
  color: #fff;
  text-decoration: none;
  cursor: "pointer"
}

.fc-event {
  background-color: #fff;
  position: relative;
  display: block;
  font-size: 0.85rem;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #eee;
  box-shadow: 0 1px 15px 1px rgb(0 0 0 / 5%);
  padding-right: 10px;
}

.fc-bg-darkblue {
  border-left-color: #023047 !important;
}

.fc-bg-default,
.fc-bg-darkblue,
.fc-bg-blue,
.fc-bg-lightblue,
.fc-bg-green,
.fc-bg-orange {
  border-left: 0.25rem solid !important;
}

.fc-bg-default,
.fc-bg-darkblue,
.fc-bg-orange,
.fc-bg-blue,
.fc-bg-lightblue,
.fc-bg-green {
  background-color: #fff !important;
  position: relative;
}

.fc-bg-default,
.fc-bg-darkblue,
.fc-bg-orange,
.fc-bg-lightblue,
.fc-bg-green {
  background-color: #fff !important;
  position: relative;
}

.fc-event .fc-content {
  color: #20294c;
  position: relative;
  z-index: 2;
  padding: 0.5rem;
}

.fc-event .fc-title {
  font-weight: 600;
}

.fc-bg-default .fc-title i,
.fc-bg-darkblue .fc-title i,
.fc-bg-blue .fc-title i,
.fc-bg-lightblue .fc-title i,
.fc-bg-green .fc-title i,
.fc-bg-orange .fc-title i {
  font-size: 1.8rem;
  margin-right: 0.2rem;
  vertical-align: -5px;
}

.fc-bg-blue {
  border-left-color: #08a6c3 !important;
}

.fc-bg-lightblue {
  border-left-color: #8ecae6 !important;
}

.fc-bg-darkblue {
  border-left-color: #023047 !important;
}

.fc-bg-orange {
  border-left-color: #f7aa1d !important;
}

.rbc-off-range-bg {
  background: #fff;
}

.rbc-off-range {
  color: #999999;
  opacity: 0.3;
}

.rbc-toolbar .rbc-toolbar-label {
  color: #20294c;
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-top: 0.7rem;
}

.rbc-header {
  color: #20294c;
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 2rem 0;
  font-size: 16px;
}

.rbc-today {
  background-color: #fff;
}

.rbc-toolbar button {
  background-color: #f1f3f7;
  border-color: #f1f3f7;
  color: #20294c;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 2.75rem;
  padding: 0 1.25rem;
  font-size: 1rem;
  font-weight: 500 !important;
  white-space: nowrap;
  cursor: pointer;
  float: left;
  margin: 0 0 0 -1px;
  border: 0;
}

.rbc-btn-group>button:first-child:not(:last-child) {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  margin-right: 15px;
}

.rbc-btn-group:not(:first-child) button {
  margin-left: 10px;
  margin-right: 10px;
}

.rbc-btn-group button:first-child {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rbc-btn-group button:last-child {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rbc-row-segment {
  padding: 5px 0 0 0;
}

.rbc-button-link {
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  -webkit-user-select: text;
  user-select: text;
  color: rgba(26, 30, 44, 0.9);
  font-size: 15px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: auto;
  width: 100%;
  text-align: left;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none;
}

.boat-switch .react-switch-bg {
  background-color: #02304785 !important;
}

.trailer-switch .react-switch-bg {
  background-color: #08a6c385 !important;
}

.van-switch .react-switch-bg {
  background-color: #8ecae685 !important;
}

.room-switch .react-switch-bg {
  background-color: #f7aa1d85 !important;
}