
/*
 * WIDGET
 */
 .widget-header {
    background: #fff;
    padding: 0.85rem 1.4rem;
    position: relative;
    width: 100%;
  }

  .widget-header.bordered {
    border-bottom: 0.07rem solid #eee;
  }

  .widget-header.no-actions {
    padding: 1.49rem;
  }

  .widget-header h1, .widget-header h2, .widget-header h3, .widget-header h4, .widget-header h5, .widget-header h6 {
    color: #20294c;
    margin-bottom: 0;
  }